import './App.css';
import { Home } from './pages/home';
import { Portfolio } from './pages/portfolio';
import { HashRouter as Router, Routes, Route} from "react-router-dom";
import React from 'react';

function App() {
  return (
    <div id="bg">
      <Router>
        <Routes>
          <Route path="/" element={ <Home /> }/>
          <Route path="/portfolio" element={ <Portfolio /> } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
