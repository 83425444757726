// pages/home.js

import React from "react";

export function Home() {
    return (
        <div>
            <h1></h1>
        </div>
    );
}