// pages/Portfolio.js


import React from "react";

export function Portfolio() {
    return (
        <div id="main_section">
            <h1>Ryan Chessum Portfolio</h1>
            <p>
                <br/>
            </p>
            <h2>Six O'Clock Swill</h2>
            <p>Links:</p>
            <p>
                <ul>
                    <li><a href="https://sixoclockswill.net/">Website</a></li>
                    <li><a href="https://liquor-license-required.itch.io/six-oclock-swill">itch.io Page</a></li>
                    <li><a href="https://twitter.com/6oclockSwill">Twitter</a></li>
                </ul>
            </p>
            <p>
                <br/>
            </p>
            <p id="media">
                <img src="./images/socs.jpg" alt="six o'clock swill" id="media"></img>
            </p>
            <p id="media">
                <video controls="controls" width="640" height="360" autoPlay="true" loop="true" muted="true">
                    <source src="./media/SixOclockSwillAttractComp.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </p>

            <p>Six O’clock swill is my final year capstone project for my Games and Interactivity and Computer Science double degree. The game was built in Unity and is a physics-based bar tending game set in 1950’s Melbourne where players chaotically mix and serve drinks by interacting with bottles and glasses to serve customers. My role on the project was as a programmer and game designer. Some of my notable contributions include developing the customer AI system, Shaders on the bottles and glasses and UI animations. As well as regular playtesting and bug fixing.</p>
            <p>Six O’Clock Swill was a project developed over 2 semesters (approximately 9 months) with a team of 8 people. In this project our group went through all stages of game production. Starting at the concept phase we pitched several different game concepts. Then we worked on a full game design document and produced an MVP to present a working version of our idea. We then developed an Alpha build with a more fleshed out version of the game, a press kit and website for the game and a demo build which we were able to show at PAX AUS in 2022 thanks to Swinburne. We also made a final submission build as well as marketing materials and a document detailing our marketing and release strategy. Along the development process there were many reworks and iterations.</p>
            <p>The majority of the work on this project was self-guided work. Our team had to define our goals and priorities at each stage of development. We utilised scrum and worked in sprints. Each week we would define our development goals and priorities for that week and each team member would work on tasks individually. To do this we used tools such as Trello to keep track of our backlog of tasks and development goals for the current sprint. As well as Toggl to track the time we spent working on each task.</p>
            <p>
                <br/>
            </p>
            <h2>Chasing the Horizon</h2>
            <p>Links:</p>
            <p>
                <ul>
                    <li><a href="https://twitter.com/kanfastudios">Twitter</a></li>
                </ul>
            </p>
            <p>
                <br/>
            </p>
            <p id="media">
                <img src="./images/Capturehuyjhytg.JPG" alt="cth key art" id="media"></img>
            </p>

            <p id="media">
                <video controls="controls" width="640" height="360" id="media" autoPlay="true" loop="true" muted="true">
                    <source src="./media/CTH_Reel.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </p>
            <p>Chasing the Horizon is a Strategy Role Playing Game passion project that is currently in development. The project is being developed in the Unity Engine with a team of other passionate developers, designers, artists and programmers and is inspired by games like Fire Emblem and Final Fantasy Tactics. The team is hoping to have a revamped demo for the game released later this year. My contributions to the project have been as a programmer and game designer.</p>
            <p>For this project I have programmed gameplay systems including the grid-based movement, combat between units and immersive sim inspired element interaction mechanics. I have developed tools for other project collaborators to import levels and scenarios into the Unity editor. I have created scripts to read and display dialogue along with character portrait and scene art direction from JSON files. Currently, I am working on a robust saving system that accounts for multiple units with their own inventories, spell lists and randomised stat growths, the many choices the player makes that influence the campaign story and mid-level saving and loading. </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
        </div>
    );
}